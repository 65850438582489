const MESSAGES = {
  defaultMessage: "This value seems to be invalid.",
  type: {
    email: "We can't quite understand that email address. Can you try again?",
    url: "This value should be a valid url.",
    number: "This value should be a valid number.",
    integer: "This value should be a valid integer.",
    digits: "This value should be digits.",
    alphanum: "This value should be alphanumeric.",
  },
  notBlank: "This value should not be blank.",
  required: "We need you to fill this out.",
  pattern: "This value seems to be invalid.",
  min: "This value should be greater than or equal to {MIN}.",
  max: "This value should be lower than or equal to {MAX}.",
  range: "This value should be between {MIN} and {MAX}.",
  minLength: "This value is too short. It should have {MIN} characters or more.",
  maxLength: "Whoa, whoa, whoa. This is way too long. ({MAX} characters or fewer, please.)",
  length: "This value length is invalid. It should be between %s and %s characters long.",
  minCheck: "You must select at least {MIN} choices.",
  maxCheck: "You must select {MAX} choices or fewer.",
  check: "You must select between {MIN} and {MAX} choices.",
  equalTo: "This value should be the same.",
  postalCode: "Five digits or nine digits with a hyphen.",
  firstLast: "A first and last name of at least two characters each is required.",
  noUrlProtocol: "This url should omit any protocol.",
};

export default MESSAGES;
