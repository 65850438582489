/**
 * @file Forms Mixin
 * Responsible for submitting forms to servers
 * Toggling form state flags
 * Displaying messages from form responses
 *
 * @TODO: consider moving form state into Vuex-type (global state store) pattern
 */
import MESSAGES from "@/mixins/error-messages.js";

const DEFAULT_OPTS = {
  showMessage: true,
  selfDestruct: true,
};

let finallyTimeout;

export default {
  methods: {
    async submitGet(action, options) {
      options = Object.assign({}, DEFAULT_OPTS, options);
      return this.axios.get(action, options);
    },

    async submitForm(action, formData, options) {
      options = Object.assign({}, DEFAULT_OPTS, options);

      if (options.showMessage) {
        this.message = {
          text: "Submitting...",
          type: "info",
        };
      } else {
        this.message = null;
      }

      if (finallyTimeout) {
        window.clearTimeout(finallyTimeout);
      }

      return this.axios
        .post(action, formData)
        .then((response) => {
          let data = response.data;

          if (options.showMessage) {
            if (data.message) {
              this.message = {
                text: data.message,
                type: "success",
                selfDestruct: options.selfDestruct,
              };
            } else {
              this.message = null;
            }
          }

          if (options.followRedirect && data.redirect) {
            try {
              window.location.href = data.redirect;
            } catch (e) {
              window.location.reload(true);
            }
          }

          return response;
        })
        .catch((errorResponse) => {
          // When there is an error, always attempt to show it if possible.
          // Without other infrastructure in place, this is the best way
          // to let the user know when there are problems.
          if (options.showMessage) {
            let message = this.retrieveErrors(errorResponse);

            this.message = {
              text: message,
              type: "error",
            };
          }

          return Promise.reject(errorResponse);
        })
        .finally(() => {
          if (options.showMessage) {
            finallyTimeout = window.setTimeout(() => {
              if (this.message && this.message.selfDestruct) {
                this.message = null;
              }
            }, 2000);
          }
        });
    },

    async submitFormWithoutMessage(action, formData, options) {
      options = Object.assign({}, { showMessage: false }, options);

      return this.submitForm(action, formData, options);
    },

    logErrors(errorResponse) {
      logError(errorResponse);
    },

    logAndReportErrors(errorResponse) {
      this.logErrors(errorResponse);
      this.reportErrors(errorResponse);
    },

    reportErrors(errorResponse) {
      let error = this.retrieveErrors(errorResponse);

      this.message = {
        text: error,
        type: "error",
      };
    },

    retrieveErrors(errorResponse) {
      let message = "Hmm. That’s not supposed to happen. Something is wrong. Can you email support@cottonbureau.com so we can take a look?";

      const response = errorResponse.response;

      if (response && response.data) {
        if (response.data.message && response.data.message !== "Server Error") {
          message = response.data.message;
        }

        if (response.data.errors) {
          for (var property in response.data.errors) {
            message += " " + response.data.errors[property];
          }
        }

        if (response.data.incidentCode) {
          message += " Incident Code: " + response.data.incidentCode;
        }
      }

      return message;
    },
  },
};

/**
 * Returns the first invalid key found in the model
 * @param  {Object} model
 * @param  {Object} validationModel
 * @return {String|null}              Name of the invalid model property
 */
export function findFirstError(model, validationModel) {
  let modelKeys = Object.keys(model);

  for (let i = 0; i < modelKeys.length; i++) {
    if (modelKeys[i] in validationModel && validationModel[modelKeys[i]].$invalid) {
      return modelKeys[i];
    }
  }
}

export function getErrorMessageByValidationModel(model, validationMessage) {
  let errorMessage = "";

  if (!model) {
    return "";
  }

  if (model.required !== undefined && model.required.$invalid) {
    errorMessage = MESSAGES.required;
  } else if (model.maxLength !== undefined && model.maxLength.$invalid) {
    errorMessage = MESSAGES.maxLength.replace("{MAX}", model.maxLength.$params.max);
  } else if (model.minLength !== undefined && model.minLength.$invalid) {
    errorMessage = MESSAGES.minLength.replace("{MIN}", model.minLength.$params.min);
  } else if (model.postalCode !== undefined && model.postalCode.$invalid) {
    errorMessage = MESSAGES.postalCode;
  } else if (model.email !== undefined && model.email.$invalid) {
    errorMessage = MESSAGES.type.email;
  } else if (model.firstLast !== undefined && model.firstLast.$invalid) {
    errorMessage = MESSAGES.firstLast;
  } else if (model.passwordIsStrong !== undefined && model.passwordIsStrong.$invalid) {
    errorMessage = model.passwordIsStrong.$message;
  } else if (model.noUrlProtocol !== undefined && model.noUrlProtocol.$invalid) {
    errorMessage = MESSAGES.noUrlProtocol;
  } else if (model.pattern !== undefined && model.pattern.$invalid) {
    errorMessage = model.pattern.$params.errorMessage;
  } else if (model.customError) {
    errorMessage = model.customError;
  } else {
    errorMessage = validationMessage || MESSAGES.defaultMessage;
  }

  return errorMessage;
}

export function logError(errorResponse) {
  let exception;
  let extra = errorResponse.config;
  let sendError = true;
  let response = errorResponse.response;

  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    exception = response.data && response.data.message;
    extra = {
      headers: response.headers,
      status: response.status,
    };
    if (response.status < 500) sendError = false;
  } else if (errorResponse.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    exception = errorResponse.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    exception = errorResponse.message;
  }

  if (sendError) {
    logException(exception, extra);
  }
}

export function logException(exception, extra) {
  // Raven.captureException(exception || 'Unknown exception', {
  //   extra: extra
  // });
}
